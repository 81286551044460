import React, { useRef, useState, useEffect } from "react";
import Popup from "./CustomPopup";

// import car111 from "../assets/ast/car111.jpg";
// import car1 from "../assets/ast/car1.png";
import car1 from "../assets/ast/axelar_logo.png";
import car2 from "../assets/ast/evmos_logo.png";
import car3 from "../assets/ast/coin.png";
// import car3 from "../assets/ast/car3.png";
import car4 from "../assets/ast/gravity.jpg";
import car5 from "../assets/ast/car5.png";
import car6 from "../assets/ast/car6.png";
import car7 from "../assets/ast/car7.png";
import car8 from "../assets/ast/car8.png";
import car9 from "../assets/ast/umee.png";
import car10 from "../assets/ast/nomic.png";
import Boxbg from "../assets/Qubelabs-box.png";
import contentCapy from "../assets/content-copy.png";
import externallink from "../assets/external-link.png";
import questionCircle from "../assets/question-circle.png";
import { anim, ScrollReveal } from "../container/ScrollReveal";

export default function Staking() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen_Evomas, setIsOpen_Evomas] = useState(false);
  const [isOpen_Agoric, setIsOpen_Agoric] = useState(false);
  const [isOpen_Fantom, setIsOpen_Fantom] = useState(false);
  const [isOpen_GravityBridge, setIsOpen_GravityBridge] = useState(false);
  const [isOpen_Nomic, setIsOpen_Nomic] = useState(false);
  const [CartValue, setCartValue] = useState(0);
  const [ViewCards, setViewCards] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [tooltipText, settooltipText] = useState("");
  const [modalContent, setModalContent] = useState([
    { name: "Axelar Network", urlLiks: car1, percentCount: "7.50%" },
    { name: "Evomas", urlLiks: car2, percentCount: "11.00%" },
    { name: "Egoric", urlLiks: car3, percentCount: "12.00%" },
    { name: "Axelar Network", urlLiks: car4, percentCount: "15.00%" },
    {name: "Gravity Bridge", urlLiks: car4, percentCount:  "15.00%"},
    {name: "Nomic", urlLiks: car10, percentCount: "15.00%"}
  ]);

  const count = useRef(0);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    count.current = count.current + 1;
  }, []);

  const handleScroll = (e) => {
    // alert("Hello World");
    setIsOpentest(false);
    setIsOpen(false);
    setIsOpen_Evomas(false);
    setIsOpen_Agoric(false);
    setIsOpen_Fantom(false);
  };
  // ==popup==
  const [isOpentest, setIsOpentest] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const togglePopupStake = (inputValue) => {
    setIsOpen(true);
    // console.log(inputValue);
    setCartValue(inputValue);
    // setIsOpentest(true);
    // console.log(isOpentest);
    // console.log(isOpen);
  };
  const togglePopupStake1 = (inputValue) => {
    setIsOpen_Evomas(true);
    // console.log(inputValue);
    setCartValue(inputValue);
    // setIsOpentest(true);
    // console.log(isOpentest);
    // console.log(isOpen);
  };
  const togglePopupStake2 = (inputValue) => {
    setIsOpen_Agoric(true);
    // console.log(inputValue);
    setCartValue(inputValue);
    // setIsOpentest(true);
    // console.log(isOpentest);
    // console.log(isOpen);
  };
  const togglePopupStake3 = (inputValue) => {
    setIsOpen_Fantom(true);
    // console.log(inputValue);
    setCartValue(inputValue);
    // setIsOpentest(true);
    // console.log(isOpentest);
    // console.log(isOpen);
  };
  const togglePopupStake4 = (inputValue) => {
    setIsOpen_GravityBridge(true);
    // console.log(inputValue);
    setCartValue(inputValue);
    // setIsOpentest(true);
    // console.log(isOpentest);
    // console.log(isOpen);
  };
  const togglePopupStake5 = (inputValue) => {
    setIsOpen_Nomic(true);
    // console.log(inputValue);
    setCartValue(inputValue);
    // setIsOpentest(true);
    // console.log(isOpentest);
    // console.log(isOpen);
  };

  const togglePopupStakeClose = () => {
    setIsOpen(false);
    setIsOpen_Evomas(false);
    setIsOpen_Agoric(false);
    setIsOpen_Fantom(false);
    setIsOpentest(false);
    setIsOpen_GravityBridge(false);
    setIsOpen_Nomic(false);
    setTimeout(TestFun(), 10000);
  };
  const copyToClipboard = (e) => {
    // textAreaRef.current.select();
    // document.execCommand("copy");
    // e.target.focus();
    // setCopySuccess("Copied!");
    // alert("dd");
    //  console.log(e.target.value);
  };
  function TestFun() {
    setIsOpen(false);
  }
  const handleMouseInClick = async (address) => {
    await navigator.clipboard.writeText(address);
    settooltipText(true);
    setTimeout(() => {
      settooltipText(false);
    }, 1000);
  };

  const redirect = () => {
    window.open("http://95.217.212.255:3000/#staking");
  };

  return (
    <section id="networks" className="mt-24">
      {isOpen && (
        <div className={"sticky top-0 justify-center z-40"}>
          <Popup
            content={
              <>
                <div classs="container  p-5">
                  <div className="container">
                    <div className=" d-flex justify-content-center">
                      <div class="media ml-5 custom-object d-flex">
                        <img alt="..." src={car1} className="img-object" />
                        <div class="media-body">
                          <h4 className="fantom-card">
                            {modalContent[CartValue]?.name}
                          </h4>
                          {/* <h4 className="fantom-card">Fantom (FTM)</h4> */}
                          <a
                            href="https://axelar.network"
                            target="_blank"
                            className="p-card"
                            rel="noreferrer"
                          >
                            https://axelar.network{" "}
                            <img src={externallink} alt="" />
                          </a>
                        </div>

                        {/* <div className="fantom-modal-header">
                      <img alt="..." src={car1} className="img111" />
                        <h4 className="fantom-card">
                          Fantom (FTM)
                        </h4>
                        <p className="p-card">ttps://fantom.foundation</p>
                      </div> */}
                      </div>
                    </div>
                    {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}

                    {/* /-------------------------------------------------------------------------------- */}

                    <div className="row"></div>
                    <div className="row row-wrapper">
                      <div className="col-12 col-md-6 order-1 order-md-0">
                        {/* <div class="tooltip">
                        <span class="tooltiptext">Tooltip text</span>
                      </div> */}
                        <div className="card-headnig tooltip">
                          <h4>Operator Address</h4>
                          <span
                            class="flex relative"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Copy to clipboard"
                          >
                            <img
                              onClick={() =>
                                handleMouseInClick(
                                  "axelarvaloper19wz0kfzj2czmjg9052h69wk6kgxc848hxs8rhl"
                                )
                              }
                              src={contentCapy}
                              alt=""
                            />
                            {tooltipText ? (
                              <span class="tooltiptext"> Address copied </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <p
                          className="p-card-link break-words"
                          ref={textAreaRef}
                          href="axelarvaloper19wz0kfzj2czmjg9052h69wk6kgxc848hxs8rhl"
                        >
                          axelarvaloper19wz0kfzj2czmjg9052h69wk6kgxc848hxs8rhl
                        </p>

                        <a className=" d-flex   qustion-img" >
                          <img src={questionCircle} alt="" />
                          <span>How to stake</span>
                        </a>
                      </div>
                      <div className="col-12 col-md-6 order-0 order-md-1">
                        <div className="row  justify-around">
                          <div className="col-8 ">
                            <div className="d-flex justify-start">
                              <div className="rate-card">
                                <h3 className="card-headnig">
                                  Annual Reward Rate
                                </h3>
                                {/* <p className="fantom-card1">15.00%</p> */}
                                <p className="fantom-card1">soon</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-end">
                              <div className="rate-card ">
                                <h3 className="card-headnig">Commission</h3>
                                <p className="fantom-card2">10%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="steke-sm-btn-none">
                          <div className="w-full">
                            <a href="https://wallet.keplr.app/#/axelar/stake?modal=stake&validator=axelarvaloper19wz0kfzj2czmjg9052h69wk6kgxc848hxs8rhl" target="_blank">
                              <button
                              type="button"
                              className="steke-model-btn  w-full"
                              
                            >
                              Stake
                            </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-footer">
                      <div className="row">
                        <div className="col-6">
                          <div className=" steke-sm-btn-custom">
                            <div className=" d-flex justify-content-start">
                              <a
                                className=" d-flex   qustion-img-mobile"
                        
                              >
                                <img src={questionCircle} alt="" />
                                <span>How to stake</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" steke-sm-btn">
                            <div className=" d-flex justify-content-start">
                            <a href="https://wallet.keplr.app/#/axelar/stake?modal=stake&validator=axelarvaloper19wz0kfzj2czmjg9052h69wk6kgxc848hxs8rhl" target="_blank">
                               <button
                                type="button"
                                className="steke-model-btn-mobile "
                              >
                                Stake
                              </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
            handleClose={togglePopupStakeClose}
            handleCloseValue={isOpen}
            handleCloseTest={isOpentest}
            // handleContent={modalContent[0]}
          />
        </div>
      )}

      {isOpen_Evomas && (
        <div className={"sticky top-0 justify-center z-40"}>
          <Popup
            content={
              <>
                <div classs="container  p-5">
                  <div className="container">
                    <div className=" d-flex justify-content-center">
                      <div class="media ml-5 custom-object d-flex">
                        <img alt="..." src={car2} className="img-object" />
                        <div class="media-body">
                          <h4 className="fantom-card">Evmos</h4>
                          {/* <h4 className="fantom-card">Fantom (FTM)</h4> */}
                          <a
                            href="https://evmos.org"
                            target="_blank"
                            className="p-card"
                            rel="noreferrer"
                          >
                            https://evmos.org <img src={externallink} alt="" />
                          </a>
                        </div>

                        {/* <div className="fantom-modal-header">
                      <img alt="..." src={car1} className="img111" />
                        <h4 className="fantom-card">
                          Fantom (FTM)
                        </h4>
                        <p className="p-card">ttps://fantom.foundation</p>
                      </div> */}
                      </div>
                    </div>
                    {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}

                    {/* /-------------------------------------------------------------------------------- */}

                    <div className="row"></div>
                    <div className="row row-wrapper">
                      <div className="col-12 col-md-6 order-1 order-md-0 ">
                        {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}
                        <div className="card-headnig tooltip relative">
                          <h4>Operator Address</h4>
                          <span
                            class="flex relative"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Copy to clipboard"
                          >
                            {tooltipText ? (
                              <span class="tooltiptext absolute right-1">
                                {" "}
                                Address copied{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            <img
                              onClick={() =>
                                handleMouseInClick(
                                  "evmosvaloper1f08fffp8zz42jj0f6wh3gyu7he4hf6zzs6mn8j"
                                )
                              }
                              src={contentCapy}
                              alt=""
                            />
                          </span>
                        </div>
                        <p
                          className="p-card-link break-words"
                          ref={textAreaRef}
                          href="evmosvaloper1f08fffp8zz42jj0f6wh3gyu7he4hf6zzs6mn8j"
                        >
                          evmosvaloper1f08fffp8zz42jj0f6wh3gyu7he4hf6zzs6mn8j
                        </p>

                        <a className=" d-flex   qustion-img">
                          <img src={questionCircle} alt="" />
                          <span>How to stake</span>
                        </a>
                      </div>
                      <div className="col-12 col-md-6 order-0 order-md-1 ">
                        <div className="row  justify-around">
                          <div className="col-8 ">
                            <div className="d-flex justify-start">
                              <div className="rate-card">
                                <h3 className="card-headnig">
                                  Annual Reward Rate
                                </h3>
                                <p className="fantom-card1">14%</p>
                                {/*  <p className="fantom-card1">{modalContent[CartValue]?.percentCount}</p>*/}
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-end">
                              <div className="rate-card ">
                                <h3 className="card-headnig">Fees</h3>
                                <p className="fantom-card2">5%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="steke-sm-btn-none">
                          <div className="w-full">
                          <a href="https://wallet.keplr.app/#/evmos/stake?modal=stake&validator=evmosvaloper1f08fffp8zz42jj0f6wh3gyu7he4hf6zzs6mn8j" target="_blank">
                             <button
                              type="button"
                              className="steke-model-btn  w-full"
                                                         >
                                                           
                              Stake
                            
                        
                            </button>   </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-footer">
                      <div className="row">
                        <div className="col-6">
                          <div className=" steke-sm-btn-custom">
                            <div className=" d-flex justify-content-start">
                              <a
                                className=" d-flex   qustion-img-mobile"
                                
                              >
                                <img src={questionCircle} alt="" />
                                <span>How to stake</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" steke-sm-btn">
                            <div className=" d-flex justify-content-start">
                            <a href="https://wallet.keplr.app/#/evmos/stake?modal=stake&validator=evmosvaloper1f08fffp8zz42jj0f6wh3gyu7he4hf6zzs6mn8j" target="_blank">
                              <button
                                type="button"
                                className="steke-model-btn-mobile "
                              >
                                Stake
                              </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------------------ */}
                  </div>
                </div>
              </>
            }
            handleClose={togglePopupStakeClose}
            handleCloseValue={isOpen_Evomas}
            handleCloseTest={isOpentest}
            // handleContent={modalContent[0]}
          />
        </div>
      )}

      {/* agoric */}
      {isOpen_Agoric && (
        <div className={"sticky top-0 justify-center z-40"}>
          <Popup
            content={
              <>
                <div classs="container  p-5">
                  <div className="container">
                    <div className=" d-flex justify-content-center">
                      <div class="media ml-5 custom-object d-flex">
                        <img alt="..." src={car3} className="img-object" />
                        <div class="media-body">
                          <h4 className="fantom-card">Agoric</h4>
                          {/* <h4 className="fantom-card">Fantom (FTM)</h4> */}
                          <a
                            href="https://agoric.com"
                            target="_blank"
                            className="p-card"
                            rel="noreferrer"
                          >
                            https://agoric.com <img src={externallink} alt="" />
                          </a>
                        </div>

                        {/* <div className="fantom-modal-header">
                      <img alt="..." src={car1} className="img111" />
                        <h4 className="fantom-card">
                          Fantom (FTM)
                        </h4>
                        <p className="p-card">ttps://fantom.foundation</p>
                      </div> */}
                      </div>
                    </div>
                    {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}

                    {/* /-------------------------------------------------------------------------------- */}

                    <div className="row"></div>
                    <div className="row row-wrapper">
                      <div className="col-12 col-md-6 order-1 order-md-0 ">
                        {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}
                        <div className="card-headnig tooltip relative">
                          <h4>Operator Address</h4>
                          <span
                            class="flex relative"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Copy to clipboard"
                          >
                            {tooltipText ? (
                              <span class="tooltiptext absolute right-1">
                                {" "}
                                Address copied{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            <img
                              onClick={() =>
                                handleMouseInClick(
                                  "agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt"
                                )
                              }
                              src={contentCapy}
                              alt=""
                            />
                          </span>
                        </div>
                        <p
                          className="p-card-link break-words"
                          ref={textAreaRef}
                          href="agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt"
                        >
                          agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt
                        </p>

                        <a className=" d-flex   qustion-img" >
                          <img src={questionCircle} alt="" />
                          <span>How to stake</span>
                        </a>
                      </div>
                      <div className="col-12 col-md-6 order-0 order-md-1 ">
                        <div className="row  justify-around">
                          <div className="col-8 ">
                            <div className="d-flex justify-start">
                              <div className="rate-card">
                                <h3 className="card-headnig">
                                  Annual Reward Rate
                                </h3>
                                {/* <p className="fantom-card1">15.00%</p> */}
                                <p className="fantom-card1">N/A%</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-end">
                              <div className="rate-card ">
                                <h3 className="card-headnig">Fees</h3>
                                <p className="fantom-card2">5%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="steke-sm-btn-none">
                          <div className="w-full">
                          <a href="https://wallet.keplr.app/#/agoric/stake?modal=stake&validator=agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt" target="_blank">
                            <button
                              type="button"
                              className="steke-model-btn w-full"
                              
                            >
                              Stake
                            </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-footer">
                      <div className="row">
                        <div className="col-6">
                          <div className=" steke-sm-btn-custom">
                            <div className=" d-flex justify-content-start">
                              <a
                                className=" d-flex   qustion-img-mobile"
                              >
                                <img src={questionCircle} alt="" />
                                <span>How to stake</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" steke-sm-btn">
                            <div className=" d-flex justify-content-start">
                            <a href="https://wallet.keplr.app/#/agoric/stake?modal=stake&validator=agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt" target="_blank">
                               <button
                                type="button"
                                className="steke-model-btn-mobile "
                              >
                                Stake
                              </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------------------ */}
                  </div>
                </div>
              </>
            }
            handleClose={togglePopupStakeClose}
            handleCloseValue={isOpen_Agoric}
            handleCloseTest={isOpentest}
            // handleContent={modalContent[0]}
          />
        </div>
      )}

      {/* GravityBridge */}
      {isOpen_GravityBridge && (
        <div className={"sticky top-0 justify-center z-40"}>
          <Popup
            content={
              <>
                <div classs="container  p-5">
                  <div className="container">
                    <div className=" d-flex justify-content-center">
                      <div class="media ml-5 custom-object d-flex">
                        <img alt="..." src={car4} className="img-object" />
                        <div class="media-body">
                          <h4 className="fantom-card"> {modalContent[CartValue]?.name}</h4>
                          {/* <h4 className="fantom-card">Fantom (FTM)</h4> */}
                          <a
                            href="https://www.gravitybridge.net/"
                            target="_blank"
                            className="p-card"
                            rel="noreferrer"
                          >
                            https://www.gravitybridge.net{" "}
                            <img src={externallink} alt="" />
                          </a>
                        </div>

                        {/* <div className="fantom-modal-header">
                      <img alt="..." src={car1} className="img111" />
                        <h4 className="fantom-card">
                          Fantom (FTM)
                        </h4>
                        <p className="p-card">ttps://fantom.foundation</p>
                      </div> */}
                      </div>
                    </div>
                    {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}

                    {/* /-------------------------------------------------------------------------------- */}

                    <div className="row"></div>
                    <div className="row row-wrapper">
                      <div className="col-12 col-md-6 order-1 order-md-0 ">
                        {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}
                        <div className="card-headnig tooltip relative">
                          <h4>Operator Address</h4>
                          <span
                            class="flex relative"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Copy to clipboard"
                          >
                            {tooltipText ? (
                              <span class="tooltiptext absolute right-1">
                                {" "}
                                Address copied{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            <img
                              onClick={() =>
                                handleMouseInClick(
                                  "gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l"
                                )
                              }
                              src={contentCapy}
                              alt=""
                            />
                          </span>
                        </div>
                        <p
                          className="p-card-link break-words"
                          ref={textAreaRef}
                          href="gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l"
                        >
                         gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l
                        </p>

                        <a className=" d-flex   qustion-img" >
                          <img src={questionCircle} alt="" />
                          <span>How to stake</span>
                        </a>
                      </div>
                      <div className="col-12 col-md-6 order-0 order-md-1 ">
                        <div className="row  justify-around">
                          <div className="col-8 ">
                            <div className="d-flex justify-start">
                              <div className="rate-card">
                                <h3 className="card-headnig">
                                  Annual Reward Rate
                                </h3>
                                {/* <p className="fantom-card1">15.00%</p> */}
                                <p className="fantom-card1">15%</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-end">
                              <div className="rate-card ">
                                <h3 className="card-headnig">Fees</h3>
                                <p className="fantom-card2">5%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="steke-sm-btn-none">
                          <div className="w-full">
                          <a href="https://www.mintscan.io/gravity-bridge/validators/gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l" target="_blank">
                            <button
                              type="button"
                              className="steke-model-btn w-full"
                              
                            >
                              Stake
                            </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-footer">
                      <div className="row">
                        <div className="col-6">
                          <div className=" steke-sm-btn-custom">
                            <div className=" d-flex justify-content-start">
                              <a
                                className=" d-flex   qustion-img-mobile"
                                
                              >
                                <img src={questionCircle} alt="" />
                                <span>How to stake</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" steke-sm-btn">
                            <div className=" d-flex justify-content-start">
                            <a href="https://www.mintscan.io/gravity-bridge/validators/gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l" target="_blank">
                              <button
                                type="button"
                                className="steke-model-btn-mobile "
                              >
                                Stake
                              </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------------------ */}
                  </div>
                </div>
              </>
            }
            handleClose={togglePopupStakeClose}
            handleCloseValue={isOpen_GravityBridge}
            handleCloseTest={isOpentest}
            // handleContent={modalContent[0]}
          />
        </div>
      )}

      {/* Nomic */}
      {isOpen_Nomic && (
        <div className={"sticky top-0 justify-center z-40"}>
          <Popup
            content={
              <>
                <div classs="container  p-5">
                  <div className="container">
                    <div className=" d-flex justify-content-center">
                      <div class="media ml-5 custom-object d-flex">
                        <img alt="..." src={car10} className="img-object" />
                        <div class="media-body">
                          <h4 className="fantom-card"> {modalContent[CartValue]?.name}</h4>
                          {/* <h4 className="fantom-card">Fantom (FTM)</h4> */}
                          <a
                            href="https://nomic.io/"
                            target="_blank"
                            className="p-card"
                            rel="noreferrer"
                          >
                            https://nomic.io{" "}
                            <img src={externallink} alt="" />
                          </a>
                        </div>

                        {/* <div className="fantom-modal-header">
                      <img alt="..." src={car1} className="img111" />
                        <h4 className="fantom-card">
                          Fantom (FTM)
                        </h4>
                        <p className="p-card">ttps://fantom.foundation</p>
                      </div> */}
                      </div>
                    </div>
                    {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}

                    {/* /-------------------------------------------------------------------------------- */}

                    <div className="row"></div>
                    <div className="row row-wrapper">
                      <div className="col-12 col-md-6 order-1 order-md-0 ">
                        {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}
                        <div className="card-headnig tooltip relative">
                          <h4>Operator Address</h4>
                          <span
                            class="flex relative"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Copy to clipboard"
                          >
                            {tooltipText ? (
                              <span class="tooltiptext absolute right-1">
                                {" "}
                                Address copied{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            <img
                              onClick={() =>
                                handleMouseInClick(
                                  "agoricvaloper1x34ru60cx0jhaeucnwccg904q26yycw7jmgxxt"
                                )
                              }
                              src={contentCapy}
                              alt=""
                            />
                          </span>
                        </div>
                        <p
                          className="p-card-link break-words"
                          ref={textAreaRef}
                          href="338sjLktF4XzRfz25oyH11jVYhZMokbsr"
                        >
                         
                        </p>

                        <a className=" d-flex   qustion-img" >
                          <img src={questionCircle} alt="" />
                          <span>How to stake</span>
                        </a>
                      </div>
                      <div className="col-12 col-md-6 order-0 order-md-1 ">
                        <div className="row  justify-around">
                          <div className="col-8 ">
                            <div className="d-flex justify-start">
                              <div className="rate-card">
                                <h3 className="card-headnig">
                                  Annual Reward Rate
                                </h3>
                                <p className="fantom-card1">N/A%</p>
                                {/* <p className="fantom-card1">
                                  {modalContent[CartValue]?.percentCount}
                                </p> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-end">
                              <div className="rate-card ">
                                <h3 className="card-headnig">Fees</h3>
                                <p className="fantom-card2">0%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="steke-sm-btn-none">
                          <div className="w-full">
                          <a href="https://app.nomic.io/#stake" target="_blank">
                             <button
                              type="button"
                              className="steke-model-btn w-full"
                             
                            >
                              Stake
                            </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-footer">
                      <div className="row">
                        <div className="col-6">
                          <div className=" steke-sm-btn-custom">
                            <div className=" d-flex justify-content-start">
                              <a
                                className=" d-flex   qustion-img-mobile"
                                
                              >
                                <img src={questionCircle} alt="" />
                                <span>How to stake</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" steke-sm-btn">
                            <div className=" d-flex justify-content-start">
                               <a href="https://app.nomic.io/#stake" target="_blank">
                                <button
                                type="button"
                                className="steke-model-btn-mobile "
                              >
                                Stake
                              </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------------------ */}
                  </div>
                </div>
              </>
            }
            handleClose={togglePopupStakeClose}
            handleCloseValue={isOpen_Nomic}
            handleCloseTest={isOpentest}
            // handleContent={modalContent[0]}
          />
        </div>
      )}

      {/* fantom */}

      {isOpen_Fantom && (
        <div className={"sticky top-0 justify-center z-40"}>
          <Popup
            content={
              <>
                <div classs="container  p-5">
                  <div className="container">
                    <div className=" d-flex justify-content-center">
                      <div class="media ml-5 custom-object d-flex">
                        <img alt="..." src={car4} className="img-object" />
                        <div class="media-body">
                          <h4 className="fantom-card">Gravity Bridge</h4>
                          {/* <h4 className="fantom-card">Fantom (FTM)</h4> */}
                          <a
                            href="https://www.gravitybridge.net/"
                            target="_blank"
                            className="p-card"
                            rel="noreferrer"
                          >
                            https://www.gravitybridge.net{" "}
                            <img src={externallink} alt="" />
                          </a>
                        </div>

                        {/* <div className="fantom-modal-header">
                      <img alt="..." src={car1} className="img111" />
                        <h4 className="fantom-card">
                          Fantom (FTM)
                        </h4>
                        <p className="p-card">ttps://fantom.foundation</p>
                      </div> */}
                      </div>
                    </div>
                    {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}

                    {/* /-------------------------------------------------------------------------------- */}

                    <div className="row"></div>
                    <div className="row row-wrapper">
                      <div className="col-12 col-md-6 order-1 order-md-0 ">
                        {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}
                        <div className="card-headnig tooltip relative">
                          <h4>Operator Address</h4>
                          <span
                            class="flex relative"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Copy to clipboard"
                          >
                            {tooltipText ? (
                              <span class="tooltiptext absolute right-1">
                                {" "}
                                Address copied{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            <img
                              onClick={() =>
                                handleMouseInClick(
                                  "gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l"
                                )
                              }
                              src={contentCapy}
                              alt=""
                            />
                          </span>
                        </div>
                        <p
                          className="p-card-link break-words"
                          ref={textAreaRef}
                          href="gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l"
                        >
                          gravityvaloper1pcea4mg8fxayytlfl5qme74md7l9znqd3uw92l
                        </p>

                        <a className=" d-flex   qustion-img" onClick={redirect}>
                          <img src={questionCircle} alt="" />
                          <span>How to stake</span>
                        </a>
                      </div>
                      <div className="col-12 col-md-6 order-0 order-md-1 ">
                        <div className="row  justify-around">
                          <div className="col-8 ">
                            <div className="d-flex justify-start">
                              <div className="rate-card">
                                <h3 className="card-headnig">
                                  Annual Reward Rate
                                </h3>
                                <p className="fantom-card1">15.00%</p>
                                {/* <p className="fantom-card1">
                                  {modalContent[CartValue]?.percentCount}
                                </p> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-end">
                              <div className="rate-card ">
                                <h3 className="card-headnig">Fees</h3>
                                <p className="fantom-card2">5.00%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="steke-sm-btn-none">
                          <div className="w-full">
                            <button
                              type="button"
                              className="steke-model-btn  w-full"
                              onClick={redirect}
                            >
                              Stake
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-footer">
                      <div className="row">
                        <div className="col-6">
                          <div className=" steke-sm-btn-custom">
                            <div className=" d-flex justify-content-start">
                              <a
                                className=" d-flex   qustion-img-mobile"
                                onClick={redirect}
                              >
                                <img src={questionCircle} alt="" />
                                <span>How to stake</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" steke-sm-btn">
                            <div className=" d-flex justify-content-start">
                              <button
                                type="button"
                                className="steke-model-btn-mobile "
                              >
                                Stake
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------------------ */}
                  </div>
                </div>
              </>
            }
            handleClose={togglePopupStakeClose}
            handleCloseValue={isOpen_Fantom}
            handleCloseTest={isOpentest}
            // handleContent={modalContent[0]}
          />
        </div>
      )}

      {/* umee */}

      {isOpen_Fantom && (
        <div className={"sticky top-0 justify-center z-40"}>
          <Popup
            content={
              <>
                <div classs="container  p-5">
                  <div className="container">
                    <div className=" d-flex justify-content-center">
                      <div class="media ml-5 custom-object d-flex">
                        <img alt="..." src={car9} className="img-object" />
                        <div class="media-body">
                          <h4 className="fantom-card">Umee</h4>
                          {/* <h4 className="fantom-card">Fantom (FTM)</h4> */}
                          <a
                            href="https://umee.cc"
                            target="_blank"
                            className="p-card"
                            rel="noreferrer"
                          >
                            https://umee.cc <img src={externallink} alt="" />
                          </a>
                        </div>

                        {/* <div className="fantom-modal-header">
                      <img alt="..." src={car1} className="img111" />
                        <h4 className="fantom-card">
                          Fantom (FTM)
                        </h4>
                        <p className="p-card">ttps://fantom.foundation</p>
                      </div> */}
                      </div>
                    </div>
                    {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}

                    {/* /-------------------------------------------------------------------------------- */}

                    <div className="row"></div>
                    <div className="row row-wrapper">
                      <div className="col-12 col-md-6 order-1 order-md-0 ">
                        {/* <div class="tooltip">Hover over me
                      <span class="tooltiptext">Tooltip text</span>
                    </div> */}
                        <div className="card-headnig tooltip relative">
                          <h4>Operator Address</h4>
                          <span
                            class="flex relative"
                            data-placement="top"
                            data-toggle="tooltip"
                            title="Copy to clipboard"
                          >
                            {tooltipText ? (
                              <span class="tooltiptext absolute right-1">
                                {" "}
                                Address copied{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            <img
                              onClick={() =>
                                handleMouseInClick(
                                  "umeevaloper1qzulky8hacycvxrz2jcts28zg7nypce88590zp"
                                )
                              }
                              src={contentCapy}
                              alt=""
                            />
                          </span>
                        </div>
                        <p
                          className="p-card-link break-words"
                          ref={textAreaRef}
                          href="umeevaloper1qzulky8hacycvxrz2jcts28zg7nypce88590zp"
                        >
                          umeevaloper1qzulky8hacycvxrz2jcts28zg7nypce88590zp
                        </p>

                        <a className=" d-flex   qustion-img" >
                          <img src={questionCircle} alt="" />
                          <span>How to stake</span>
                        </a>
                      </div>
                      <div className="col-12 col-md-6 order-0 order-md-1 ">
                        <div className="row  justify-around">
                          <div className="col-8 ">
                            <div className="d-flex justify-start">
                              <div className="rate-card">
                                <h3 className="card-headnig">
                                  Annual Reward Rate
                                </h3>
                                <p className="fantom-card1">83%</p>
                                {/* <p className="fantom-card1">{modalContent[CartValue]?.percentCount}</p> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-end">
                              <div className="rate-card ">
                                <h3 className="card-headnig">Commission</h3>
                                <p className="fantom-card2">5%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="steke-sm-btn-none">
                          <div className=" w-full">
                          <a href="https://wallet.keplr.app/#/umee/stake?modal=stake&validator=umeevaloper1qzulky8hacycvxrz2jcts28zg7nypce88590zp" target="_blank">
                            <button
                              type="button"
                              className="steke-model-btn  w-full"
                                                         >
                              Stake
                            </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-footer">
                      <div className="row">
                        <div className="col-6">
                          <div className=" steke-sm-btn-custom">
                            <div className=" d-flex justify-content-start">
                              <a
                                className=" d-flex   qustion-img-mobile"
                                                              >
                                <img src={questionCircle} alt="" />
                                <span>How to stake</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className=" steke-sm-btn">
                            <div className=" d-flex justify-content-start">
                            <a href="https://wallet.keplr.app/#/umee/stake?modal=stake&validator=umeevaloper1qzulky8hacycvxrz2jcts28zg7nypce88590zp" target="_blank">
                              <button
                                type="button"
                                className="steke-model-btn-mobile "
                              >
                                Stake
                              </button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------------------------------------------------------------------ */}
                  </div>
                </div>
              </>
            }
            handleClose={togglePopupStakeClose}
            handleCloseValue={isOpen_Fantom}
            handleCloseTest={isOpentest}
            // handleContent={modalContent[0]}
          />
        </div>
      )}

      <div className="container"></div>
      <div className="container-xxl relative pr-7">
        <img
          className="features-transition absolute  -left-4 -top-4 custom-left  w-96 transform"
          src={Boxbg}
          alt=""
        />

        <div className="pt-36">
          <div className="rounded-2xl bg-slate-200 bg-opacity-60 backdrop-blur-sm px-4 pb-4 pt-16 md:p-16">
            <div className="text-center">
              <ScrollReveal config={anim.animate5}>
                <h5 className="text-5xl font-bold text-primary ">
                  Stake with us
                </h5>
              </ScrollReveal>
              <ScrollReveal config={anim.animate6}>
                <p className="text-primary-dark font-medium mt-6 mb-12">
                  Earn rewards for staking your tokens. Here are the
                  networks we validate, support and help grow their communities.
                </p>
              </ScrollReveal>
            </div>

            <div class=" row no-gutters grid xxl:grid-cols-4 lg:grid-cols-2 gap-4">
              <div class="stake-card-custom col-sm-2 mx-auto my-3">
                <ScrollReveal config={anim.animate8}>
                  <div className="stake-card">
                    <div className="d-flex p-1 no-gutters justify-center">
                      <div className="row justify-center">
                        <img alt="..." src={car1} className="img111" />
                        <div className="">
                          <div className="head-text">Axelar Network</div>
                          <div className="font-bold font-bold-card">15%</div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn is-primary w-full mt-2 btn-top"
                      type="button"
                      value="Click to Open Popup"
                      onClick={() => togglePopupStake(0)}
                    >
                      Stake
                    </button>
                  </div>
                </ScrollReveal>
              </div>
              <div
                style={{ width: "14rem", padding: "12px" }}
                class="stake-card-custom  col-sm-2 mx-auto my-3"
              >
                <ScrollReveal config={anim.animate8}>
                  <div className="stake-card">
                    <div className="d-flex p-1 no-gutters justify-center">
                      <div className="row justify-center">
                        <img alt="..." src={car9} className="img111" />
                        <div className="">
                          <div className="head-text">Umee</div>
                          <div className="font-bold font-bold-card">83%</div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn is-primary w-full mt-2 btn-top"
                      type="button"
                      value="Click to Open Popup"
                      onClick={() => togglePopupStake3(3)}
                    >
                      Stake
                    </button>
                  </div>
                </ScrollReveal>
              </div>
              <div
                style={{ width: "14rem", padding: "12px" }}
                class="stake-card-custom col-sm-2 mx-auto my-3"
              >
                <ScrollReveal config={anim.animate8}>
                  <div className="stake-card">
                    <div className="d-flex p-1 no-gutters justify-center">
                      <div className="row justify-center">
                        <img alt="..." src={car2} className="img111" />
                        <div className="">
                          <div className="head-text">Evmos</div>
                          <div className="font-bold font-bold-card">14%</div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn is-primary w-full mt-2 btn-top"
                      type="button"
                      value="Click to Open Popup"
                      onClick={() => togglePopupStake1(1)}
                    >
                      Stake
                    </button>
                  </div>
                </ScrollReveal>
              </div>
              <div
                style={{ width: "14rem", padding: "12px" }}
                class="stake-card-custom col-sm-2 mx-auto my-3"
              >
                <ScrollReveal config={anim.animate8}>
                  <div className="stake-card">
                    <div className="d-flex p-1 no-gutters justify-center">
                      <div className="row justify-center">
                        <img alt="..." src={car3} className="img111" />
                        <div className="">
                          <div className="head-text">Agoric</div>
                          <div className="font-bold font-bold-card">N/A%</div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn is-primary w-full mt-2 btn-top"
                      type="button"
                      value="Click to Open Popup"
                      onClick={() => togglePopupStake2(2)}
                    >
                      Stake
                    </button>
                  </div>
                </ScrollReveal>
              </div>
              <div
                style={{ width: "14rem", padding: "12px" }}
                class="stake-card-custom  col-sm-2 mx-auto my-3"
              >
                <ScrollReveal config={anim.animate8}>
                  <div className="stake-card">
                    <div className="d-flex p-1 no-gutters justify-center">
                      <div className="row justify-center">
                        <img alt="..." src={car4} className="img111" />
                        <div className="">
                          <div className="head-text">Gravity Bridge</div>
                          <div className="font-bold font-bold-card">15%</div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn is-primary w-full mt-2 btn-top"
                      type="button"
                      value="Click to Open Popup"
                      onClick={() => togglePopupStake4(4)}
                    >
                      Stake
                    </button>
                  </div>
                </ScrollReveal>
              </div>

              <div
                style={{ width: "14rem", padding: "12px" }}
                class="stake-card-custom  col-sm-2 mx-auto my-3"
              >
                <ScrollReveal config={anim.animate8}>
                  <div className="stake-card">
                    <div className="d-flex p-1 no-gutters justify-center">
                      <div className="row justify-center">
                        <img alt="..." src={car10} className="img111" />
                        <div className="">
                          <div className="head-text">Nomic</div>
                          <div className="font-bold font-bold-card">N/A%</div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn is-primary w-full mt-2 btn-top"
                      type="button"
                      value="Click to Open Popup"
                      onClick={() => togglePopupStake5(5)}
                    >
                      Stake
                    </button>
                  </div>
                </ScrollReveal>
              </div>
            </div>

            {/* <div style={{ marginTop: "40px" }}>
              <div class="row ">
                <div style={{ width: "14rem", padding: "12px" }} class=" stake-card-custom col-sm-2 mr-auto ml-auto ">
                  <ScrollReveal config={anim.animate8}>
                    <div className="stake-card">
                      <div className="d-flex p-1 no-gutters  justify-center">
                        <div className="col w-12 h-10 m-1 flex mr-5 items-center bg-white rounded-lg">
                          <img alt="..." src={car5} />
                        </div>
                        <div className="col px-2 ml-5">
                          <div className="head-text">Kusama (KSM)</div>
                          <div className="font-bold text-2xl">15.00%</div>
                        </div>
                      </div>
                      <button
                        className="btn btn-btm w-full mt-2 btn-top"
                        type="button"
                        value="Click to Open Popup"
                        onClick={togglePopup}
                      >
                        Upcoming
                      </button>
                    </div>
                  </ScrollReveal>
                </div>
                <div style={{ width: "14rem", padding: "12px" }} class="stake-card-custom col-sm-2 ml-auto mr-auto ">
                  <ScrollReveal config={anim.animate8}>
                    <div className="stake-card">
                      <div className="d-flex p-1 no-gutters justify-center">
                        <div className="col w-12 h-10 m-1 mr-5 flex items-center bg-white rounded-lg">
                          <img alt="..." src={car6} />
                        </div>
                        <div className="col px-2 ml-5">
                          <div className="head-text">Crypto (CRO)</div>
                          <div className="font-bold text-2xl">15.00%</div>
                        </div>
                      </div>
                      <button
                        className="btn btn-btm w-full mt-2 btn-top"
                        type="button"
                        value="Click to Open Popup"
                        onClick={togglePopup}
                      >
                        Upcoming
                      </button>
                    </div>
                  </ScrollReveal>
                </div>
                <div style={{ width: "14rem", padding: "12px" }} class="stake-card-custom col-sm-2 ml-auto mr-auto ">
                  <ScrollReveal config={anim.animate8}>
                    <div className="stake-card">
                      <div className="d-flex p-1 no-gutters justify-center">
                        <div className="col w-12 h-10 m-1 mr-4 flex items-center bg-white rounded-lg">
                          <img alt="..." src={car7} />
                        </div>
                        <div className="col px-2 ml-4">
                          <div className="head-text">xDai (STAKE)</div>

                          <div className="font-bold text-2xl">15.00%</div>
                        </div>
                      </div>
                      <button
                        className="btn btn-btm w-full mt-2 btn-top"
                        type="button"
                        value="Click to Open Popup"
                        onClick={togglePopup}
                      >
                        Upcoming
                      </button>
                    </div>
                  </ScrollReveal>
                </div>
                <div style={{ width: "14rem", padding: "12px" }} class="stake-card-custom col-sm-2 ml-auto mr-auto ">
                  <ScrollReveal config={anim.animate8}>
                    <div className="stake-card">
                      <div className="d-flex p-1 no-gutters justify-center">
                        <div className="col w-12 h-10 mr-4 m-1 flex items-center bg-white rounded-lg">
                          <img alt="..." src={car8} />
                        </div>
                        <div className="col px-2 ml-4 text">
                          <div className="head-text">Avalanche (AVAX)</div>
                          <div className="font-bold text-2xl">15.00%</div>
                        </div>
                      </div>
                      <button
                        className="btn btn-btm w-full mt-2 btn-top"
                        type="button"
                        value="Click to Open Popup"
                        onClick={togglePopup}
                      >
                        Upcoming
                      </button>
                    </div>
                  </ScrollReveal>
                </div>
              </div>
            </div> */}
            <div className="row no-gutters -ml-1 -mr-1">
              {/* <ScrollReveal config={anim.animate8}>
                <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                  <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                    <div className="row no-gutters justify-center">
                      <div className="col w-14 flex items-center bg-white rounded-lg">
                        <img alt="..." src={car1} />
                      </div>
                      <div className="col px-2">
                        <div className="head-text">Cosmos (ATOM)</div>
                        <div className="font-bold text-2xl">15.00%</div>
                      </div>
                    </div>
                    <button
                      className="btn is-primary w-full mt-2"
                      type="button"
                      value="Click to Open Popup"
                      onClick={togglePopup}
                    >
                      Stake
                    </button>
                  </div>
                </div>
              </ScrollReveal>

              <ScrollReveal config={anim.animate8}>
                <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                  <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                    <div className="row no-gutters justify-center">
                      <div className="col w-14 flex items-center bg-white rounded-lg">
                        <img alt="..." src={car4} />
                      </div>
                      <div className="col px-2">
                        <div className="head-text">Polkadot (DOT)</div>
                        <div className="font-bold text-3xl">N/A</div>
                      </div>
                    </div>

                    <button className="btn is-primary w-full mt-2">Stake</button>
                  </div>
                </div>
              </ScrollReveal>

              <ScrollReveal config={anim.animate8}>
                <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                  <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                    <div className="row no-gutters justify-center">
                      <div className="col w-14 flex items-center bg-white rounded-lg">
                        <img alt="..." src={car3} />
                      </div>
                      <div className="col px-2">
                        <div className="head-text">Terra (LUNA)</div>
                        <div className="font-bold text-3xl">15.00%</div>
                      </div>
                    </div>

                    <button className="btn is-primary w-full mt-2">Stake</button>
                  </div>
                </div>
              </ScrollReveal>

              <ScrollReveal config={anim.animate8}>
                <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                  <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                    <div className="row no-gutters justify-center">
                      <div className="col w-14 flex items-center bg-white rounded-lg">
                        <img alt="..." src={car4} />
                      </div>
                      <div className="col px-2">
                        <div className="head-text" >Cosmos (ATOM)</div>
                        <div className="font-bold text-3xl">15.00%</div>
                      </div>
                    </div>
                    <button className="btn is-primary w-full mt-2">Stake</button>
                  </div>
                </div>
              </ScrollReveal>

              <ScrollReveal config={anim.animate8}>
                <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                  <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                    <div className="row no-gutters justify-center">
                      <div className="col w-14 flex items-center bg-white rounded-lg">
                        <img alt="..." src={car5} />
                      </div>
                      <div className="col px-2">
                        <div className="head-text">Kusama (KSM)</div>
                        <div className="font-bold text-3xl">15.00%</div>
                      </div>
                    </div>

                    <button className="btn w-b w-full mt-2">Upcoming</button>
                  </div>
                </div>
              </ScrollReveal>

              <ScrollReveal config={anim.animate8}>
                <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                  <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                    <div className="row no-gutters justify-center">
                      <div className="col w-14 flex items-center bg-white rounded-lg">
                        <img alt="..." src={car6} />
                      </div>
                      <div className="col px-2">
                        <div>Crypto (CRO)</div>
                        <div className="font-bold text-3xl">15.00%</div>
                      </div>
                    </div>

                    <button className="btn w-b w-full mt-2">Upcoming</button>
                  </div>
                </div>
              </ScrollReveal>

              <ScrollReveal config={anim.animate8}>
                <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                  <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                    <div className="row no-gutters justify-center">
                      <div className="col w-14 flex items-center bg-white rounded-lg">
                        <img alt="..." src={car7} />
                      </div>
                      <div className="col px-2">
                        <div>xDai (STAKE)</div>
                        <div className="font-bold text-3xl">15.00%</div>
                      </div>
                    </div>

                    <button className="btn w-b w-full mt-2">MAINNET Active</button>
                  </div>
                </div>
              </ScrollReveal>

              <ScrollReveal config={anim.animate8}>
                <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                  <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                    <div className="row no-gutters justify-center">
                      <div className="col w-14 flex items-center bg-white rounded-lg">
                        <img alt="..." src={car8} />
                      </div>
                      <div className="col px-2">
                        <div>Avalanche (AVAX)</div>
                        <div className="font-bold text-3xl">15.00%</div>
                      </div>
                    </div>

                    <button className="btn w-b w-full mt-2">TESTNET Active</button>
                  </div>
                </div>
              </ScrollReveal> */}
              {/* ------------------------------------------View-More---- */}
              {/* {ViewCards ? (
                <>
                  <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                    <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                      <div className="row no-gutters justify-center">
                        <div className="col w-14 flex items-center bg-white rounded-lg">
                          <img alt="..." src={car5} />
                        </div>
                        <div className="col px-2">
                          <div>Fantom (FTM)</div>
                          <div className="font-bold text-3xl">15.00%</div>
                        </div>
                      </div>

                      <button className="btn is-primary w-full mt-2">Stake</button>
                    </div>
                  </div>

                  <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                    <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                      <div className="row no-gutters justify-center">
                        <div className="col w-14 flex items-center bg-white rounded-lg">
                          <img alt="..." src={car6} />
                        </div>
                        <div className="col px-2">
                          <div>Fantom (FTM)</div>
                          <div className="font-bold text-3xl">15.00%</div>
                        </div>
                      </div>

                      <button className="btn is-primary w-full mt-2">Stake</button>
                    </div>
                  </div>

                  <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                    <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                      <div className="row no-gutters justify-center">
                        <div className="col w-14 flex items-center bg-white rounded-lg">
                          <img alt="..." src={car7} />
                        </div>
                        <div className="col px-2">
                          <div>Fantom (FTM)</div>
                          <div className="font-bold text-3xl">15.00%</div>
                        </div>
                      </div>

                      <button className="btn is-primary w-full mt-2">Stake</button>
                    </div>
                  </div>

                  <div className="col w-full sm:w-6/12 md:w-3/12 py-1 sm:p-1">
                    <div className="card w-full h-full card-body text-center rounded-lg p-3 bg-[#F8FAFD] border-blue-100 hover:border-blue-300 hover:shadow-lg transition-colors transition-colors border-2">
                      <div className="row no-gutters justify-center">
                        <div className="col w-14 flex items-center bg-white rounded-lg">
                          <img alt="..." src={car8} />
                        </div>
                        <div className="col px-2">
                          <div>Fantom (FTM)</div>
                          <div className="font-bold text-3xl">15.00%</div>
                        </div>
                      </div>

                      <button className="btn is-primary w-full mt-2">Stake</button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* ------------------------------------------View-More---- */}
              {/* </div>
            {ViewCards ? (
              ""
            ) : (
              <ScrollReveal config={anim.animate11}>
                <div className="mt-4 text-center" onClick={() => setViewCards(true)}>
                  <div className="btn is-primary is-expanded">View More</div>
                </div>
              </ScrollReveal>
            )} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
